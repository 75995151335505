<template>
  <div class="">
    <form>
      <!-- <h3 v-if="!SUCCESS">Cep Telefonu Doğrulama</h3> -->
      <div v-if="EXPECT || SUCCESS || FAIL" class="card" action="#">
        <div class="verified" v-if="SUCCESS">
          <div class="veriftext">
            <img src="@/assets/img/ico_tick_green.svg" alt />DOĞRULANDI
          </div>
        </div>

        <label>Cep Telefonu Numaranız</label>
        <div class="text">
          {{ number | tocontactCellPhone }}
        </div>
      </div>

      <div
        v-if="INIT"
        :class="{ dsbl: !ready_kod_gonder }"
        class="btn"
        @click="ready_kod_gonder ? SMS_KOD_GONDER_CLICK() : null"
      >
        <a>Doğrulama Kodu Gönder</a>
      </div>

      <div v-if="EXPECT || FAIL" class="formline">
        <div :class="{ notvalid: problem.approvalCode }">
          <div class="withbtn">
            <label>Doğrulama Kodu</label>
            <div class="counter">{{ counter }} sn</div>
          </div>
          <div class="desc">
            Lütfen cep telefonunuza gelen kodu giriniz
          </div>
          <input
            class="text"
            type="text"
            v-model="sms_kod"
            @input="problem.approvalCode = ''"
            @keyup.enter="SMS_DOGRULA_CLICK()"
          />
          <input style="display:none;" />
          <div class="hint">{{ problem.approvalCode }}</div>
        </div>
      </div>

      <div
        v-if="EXPECT || FAIL"
        class="btn"
        :class="{ dsbl: !NEXT }"
        @click="NEXT ? SMS_DOGRULA_CLICK() : null"
      >
        <a>Doğrula</a>
      </div>

      <!-- <div class="formline caution" v-if="INIT">
        <h4>Dikkat Edilmesi Gereken Noktalar</h4>
        <ul>
          <li>
            <span>Lütfen belirtilen süre içerisinde doğrulama kodunuzu girerek ön başvurunuzun belge yükleme adımına geçiniz.</span>
          </li>
        </ul>
      </div> -->
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CepTelDogrulama",

  props: {
    number: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      state: "INIT", //EXPECT, SUCCESS, FAIL

      captcha_str: "",

      counter: 120,
      counter_func: null,

      sms_kod_olusturuldu: false,
      sms_kod: "",
      otpID: "",

      mersis_manuel: false,

      problem: {
        idSerialNo: "",
        approvalCode: "",
        cellPhone: ""
      }
    };
  },

  beforeDestroy() {
    clearInterval(this.counter_func);
    this.counter_func = null;
  },

  watch: {
    state: {
      immediate: true,
      handler(n) {
        this.$emit("input", n);
      }
    }
  },

  computed: {
    ...mapState("applicant", ["user", "application"]),

    ready_kod_gonder() {
      return this.number.length > 10 && this.INIT;
    },

    INIT() {
      return this.state === "INIT";
    },

    EXPECT() {
      return this.state === "EXPECT";
    },

    SUCCESS() {
      return this.state === "SUCCESS";
    },

    FAIL() {
      return this.state === "FAIL";
    },

    NEXT() {
      return this.sms_kod;
    }
  },

  methods: {
    ...mapActions("applicant", ["sms_kod_olustur", "sms_kod_dogrula"]),

    ...mapMutations("applicant", [
      "SET_MOBILE",
      "SET_APPLICATION",
      "SET_SMS_ONAYI",
      "SET_ONAY_ADIMI",
      "MERGE_TO_APPLICATION"
    ]),

    maskCheck(field) {
      if (field.target.inputmask.isComplete()) {
        this.$env.CONSOLE.log("is Complete", field.target.value);
        this.problem[field.target.id] = "";
      } else {
        this.$env.CONSOLE.log("is Incomplete");
        this.problem[field.target.id] = "Hatalı veri girişi !!!";
      }
    },

    myTimer() {
      this.counter--;
      if (this.counter < 1) {
        clearInterval(this.counter_func);
        this.state = "INIT";
      }
    },

    SMS_KOD_GONDER_CLICK() {
      this.MERGE_TO_APPLICATION({
        contact: {
          cellPhone: this.number
        }
      });
      this.sms_kod_olustur()
        .then(res => {
          // _paq.push(["trackEvent", "Basvuru", "SMS_Kodu_Iste", "Success"]);
          this.state = "EXPECT";
          this.Toast("SMS Gönderildi.");

          // this.counter = 5;
          this.counter = res.payload.ttl;
          this.otpID = res.payload.id;
          this.counter_func = setInterval(this.myTimer, 1000);

          if (this.$env.MOCK) {
            this.sms_kod = res.debug.code;
          }
        })
        .catch(cerror => {
          // _paq.push(["trackEvent", "Basvuru", "SMS_Kodu_Iste", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    async SMS_DOGRULA_CLICK() {
      await this.$store.dispatch("app/friction");

      this.sms_kod_dogrula({
        oid: this.otpID,
        code: this.sms_kod,
        approvalCode: this.sms_kod
      })
        .then(res => {
          // _paq.push(["trackEvent", "Basvuru", "SMS_Dogrula", "Success"]);
          this.state = "SUCCESS";
          clearInterval(this.counter_func);

          this.SET_APPLICATION(res.payload)
          this.SET_MOBILE(this.number)

          // this.SET_SMS_ONAYI(true);
          // this.SET_ONAY_ADIMI(res.payload.step); // TODO: Burada application geliyor tümden SET edebilirsin.
        })
        .catch(cerror => {
          // _paq.push(["trackEvent", "Basvuru", "SMS_Dogrula", "Error"]);
          if (this.counter < 1) {
            this.state = "INIT";
            clearInterval(this.counter_func);
          } else {
            this.state = "FAIL";
          }
          this.HandleValidationError(cerror);
        });
    }
  }
};
</script>

<style scoped>
.kimlik {
  display: flex;
}
.links {
  display: flex !important;
}
.links a {
  display: flex;
  cursor: pointer;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  text-align: center;
  height: min-content;
  margin: 0.4vh;
  padding: 0.5vh 1vh;
  background: #fdfbfa;
  color: #4da0df;
  border-radius: 0.5vw;
  width: 100%;
}

.links a:hover {
  position: relative;
}

.links a:hover::before {
  display: flex;
  position: absolute;
  bottom: 3vh;
  border: 3px solid #a9d3ff;
  border-radius: 0.5vw;
  overflow: hidden;
  padding: 0;
  height: 250px;
  margin: 0;
  z-index: 99;
}

.links .eskikimlik:hover::before {
  content: url(../../../../assets/img/nc_eski.png);
}
.links .yenikimlik:hover::before {
  content: url(../../../../assets/img/nc_yeni.png);
}

@media (max-width: 500px) and (orientation: portrait) {
  .links a:hover::before {
    bottom: 12vh;
  }
  .links a {
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
