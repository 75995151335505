<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <div v-if="loggedIn" class="tclogin center mt">
          <p>
            <b>{{tckn_keycloak}}</b> T.C. Kimlik Numarasıyla e-Devlet girişi yapıldı. <a @click="LOGOUT">Çıkış yap</a>
          </p>
        </div>

        <h2>Hizmet Sağlayıcılar için Başvuru Formu</h2>
        <tabs-new-basvuru current="1" hide="6"></tabs-new-basvuru>
        <tabs-giris-basvuru-sub :current="current" hide="6"></tabs-giris-basvuru-sub>


        <div v-if="yonlendiriliyor" class="formline center">Giriş yapmanız için e-Devlet'e yönlendiriliyorsunuz.</div>

        <div v-if="stage==='EPOSTA' && !yonlendiriliyor" class="formpart center">
          <div class="formleft">
            <form action="#" onsubmit="return false;">
              <div class="formline">
                <div :class="{ notvalid: problem.email }" class="forminput">
                  <div v-if="dk_verified" class="card" action="#">
                    <label>E-posta Adresiniz</label>
                    <div class="text">{{ user.email }}</div>
                  </div>

                  <div v-else>
                    <label>E-posta Adresiniz</label>
                    <tool-tip v-show="!dk_verified">Lütfen kurumsal e-posta adresinizi yazınız.</tool-tip>
                    <input
                      class="test_email_yeni_basvuru_giris"
                      :class="{ input_fix_murat: dk_verified }"
                      type="text"
                      name="email"
                      autocomplete="email"
                      v-model="user.email"
                      @input="problem.email = ''"
                      @keyup.enter="$refs.capcha.focus()"
                    />
                    <div class="hint">{{ problem.email }}</div>
                  </div>
                </div>

                <capcha
                  ref="capcha"
                  v-show="!dk_verified"
                  v-model="captcha"
                  section="CREATE-OTP"
                  :message="problem.captchaCode"
                  @enter="dogrula_buton_aktif ? DOGRULA() : null"
                  @input="problem.captchaCode = ''"
                ></capcha>

                <div :class="{ notvalid: problem.otp }" v-show="dk_verified">
                  <div class="withbtn">
                    <label>Doğrulama Kodu</label>
                    <div class="counter">{{ counter }} sn</div>
                  </div>
                  <div class="desc">Lütfen e-posta adresinize gönderilen kodu giriniz</div>
                  <input
                    type="text"
                    ref="inputotp"
                    autocomplete="one-time-code"
                    v-model="otp"
                    @input="problem.otp = ''"
                    @keyup.enter="otp ? BASLAT() : null"
                  />
                  <div class="hint">Hatalı kod girdiniz.</div>
                </div>
              </div>
              <div
                v-if="!dk_verified"
                class="btn full"
                :class="{ dsbl: !dogrula_buton_aktif }"
                @click="dogrula_buton_aktif ? DOGRULA() : null"
              >
                <a>Doğrulama Kodu Gönder</a>
              </div>

              <div
                v-if="show_baslat"
                class="btn full"
                :class="{ dsbl: !otp }"
                @click="otp ? BASLAT() : null"
              >
                <a>Doğrula</a>
              </div>
            </form>
          </div>
        </div>
        <div v-if="stage==='CEPTEL'" class="formpart center">
          <div class="formleft">
            <form action="#" onsubmit="return false;">
              <div class="formline">
                <iys-input
                  v-if="cepTelDogrulaState === 'INIT'"
                  id="contactCellPhone"
                  name="cell-phone"
                  label="Cep Telefonu Numaranız"
                  v-model="contactCellPhone"
                  mask="(9999) 999-99-99"
                  placeholder="(05xx) xxx-xx-xx"
                  :problem="problem['cellPhone']"
                  @input="problem['cellPhone'] = ''"
                >
                  <tool-tip>
                    Lütfen doğrulama kodunun gönderileceği cep telefonu
                    numaranızı yazınız.
                  </tool-tip>
                </iys-input>

                <cep-tel-dogrulama v-model="cepTelDogrulaState" :number="contactCellPhone" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Keycloak from "keycloak-js";
import CepTelDogrulama from "./genel/CepTelDogrulama";

import Loading from "vue-loading-overlay";
import { mapState, mapActions, mapMutations } from "vuex";
import download from "../../../misc/download";

import EmailValidator from "email-validator";

import initOptions from "@/../keycloakConfig";
var keycloak = Keycloak(initOptions);

export default {
  name: "HSBasvuruGiris",

  components: {
    CepTelDogrulama,
  },

  data() {
    return {
      yonlendiriliyor : false,

      stage: "EPOSTA", // CEPTEL, FINAL

      cepTelDogrulaState: "",
      contactCellPhone: "",

      captcha: {
        captchaId: "",
        captchaCode: "",
      },

      counter: 120,
      counter_func: null,

      problem: {
        email: "",
        otp: "",
        captchaCode: "",
        cellPhone: "",
      },

      email_sent_successfully: false,
      otp: "",
      otpID: "",
    };
  },

  created() {
    const secim = this.$route.query.secim;

    if (secim === "e-devlet" && !this.loggedIn) {
      this.yonlendiriliyor = true;
      this.LOGIN();
    }
  },

  mounted() {
    if (this.$env.MOCK) {
      this.user.email = "caner@yetkilicicekcilik.com.tr";
      this.contactCellPhone = "05377116250";
    }
    this.$store.commit("app/HIDE_DUYURU");
  },

  beforeDestroy() {
    clearInterval(this.counter_func);
  },

  computed: {
    ...mapState("applicant", ["user"]),

    current() {
      if (this.stage === "EPOSTA") return 1;
      if (this.stage === "CEPTEL") return 2;
      return 3;
    },

    emailValidated() {
      return EmailValidator.validate(this.user.email);
    },

    dogrula_buton_aktif() {
      return this.captcha.captchaCode.length > 5 && this.emailValidated;
    },

    dk_verified() {
      return this.email_sent_successfully;
    },

    show_baslat() {
      if (this.dk_verified) {
        return true;
      }
      return false;
    },
  },

  watch: {
    cepTelDogrulaState(n) {
      if (n === "SUCCESS") {
        setTimeout(() => {
          this.$router.push("/hizmet-saglayici/basvuru/mersis-tckn");
        }, 1000);
      }
    },
  },

  methods: {
    ...mapActions("applicant", ["e_posta_dogrula", "create_basvuru"]),

    ...mapMutations("applicant", ["SET_APPLICATION"]),

    LOGOUT() {
      if (this.$keycloak) {
        this.$env.CONSOLE.warn("LOGOUT URL:", this.$keycloak.createLogoutUrl());
        this.$keycloak.logoutFn();
      } else {
        this.$env.CONSOLE.warn("ALREADY LOGGED OUT.");
      }
    },

    LOGIN() {
      keycloak
        .init({ onLoad: "login-required" })
        // .init({ onLoad: "login-required", promiseType: "native" })
        // .init()
        .then((auth) => {
          if (!auth) {
            window.location.reload();
          } else {
            Vue.$log.info("Authenticated");
            this.keycloak = keycloak;
          }

          //Token Refresh
          setInterval(() => {
            keycloak
              .updateToken(70)
              .then((refreshed) => {
                if (refreshed) {
                  Vue.$log.info("Token refreshed" + refreshed);
                } else {
                  Vue.$log.warn(
                    "Token not refreshed, valid for " +
                      Math.round(
                        keycloak.tokenParsed.exp +
                          keycloak.timeSkew -
                          new Date().getTime() / 1000
                      ) +
                      " seconds"
                  );
                }
              })
              .catch(() => {
                Vue.$log.error("Failed to refresh token");
              });
          }, 6000);
        })
        .catch(() => {
          Vue.$log.error("Authenticated Failed");
        });
    },

    myTimer() {
      this.counter--;
      if (this.counter < 1) {
        clearInterval(this.counter_func);
        this.email_sent_successfully = false;
        this.$root.$emit("reset_capcha");
      }
    },

    async DOGRULA() {
      this.clearValidationErrorDisplays();

      try {
        const res = await this.e_posta_dogrula({
          email: this.user.email,
          ...this.captcha,
        });

        if (res.ok) {
          this.email_sent_successfully = true;

          this.otpID = res.payload.id;

          this.counter = res.payload.ttl;
          this.counter_func = setInterval(this.myTimer, 1000);

          // _paq.push(["trackEvent", "Basvuru", "E-posta_Kodu_Iste", "Success"]);

          this.Toast(
            "Doğrulama kodunuz e-posta adresinize gönderildi. Lütfen kontrol ediniz."
          );

          if (this.$env.MOCK) {
            this.otp = res.debug.code;
          }

          this.$nextTick(() => {
            this.$refs.inputotp.focus();
          });
        }
      } catch (cerror) {
        // _paq.push(["trackEvent", "Basvuru", "E-posta_Kodu_Iste", "Error"]);
        this.HandleValidationError(cerror);
      }
    },

    BASLAT() {
      this.create_basvuru({
        email: this.user.email,
        code: this.otp,
        oid: this.otpID,
        // otpCode: this.otp
      })
        .then((res) => {
          // _paq.push(["trackEvent", "Basvuru", "E-posta_Dogrula", "Success"]);
          this.SET_APPLICATION(res.payload);
          clearInterval(this.counter_func);
          this.stage = "CEPTEL";
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Basvuru", "E-posta_Dogrula", "Error"]);
          this.HandleValidationError(cerror);
        });
    },
  },
};
</script>

<style scoped>
.input_fix_murat {
  pointer-events: none;
  background-color: #ebf6ff;
  border: #ffffff;
  font-weight: 600;
}
</style>
